import React from 'react';
import Main from '../layouts/main'
import { graphql } from 'gatsby'

import styles from './blogPost.module.scss'

// import PrevNext from '../components/prevnext';
import MetaTags from '../components/metatags';

function BlogPost(props) {

    const url = props.data.site.siteMetadata.siteUrl
    const thumbnail = props.data.markdownRemark.frontmatter.image &&
        props.data.markdownRemark.frontmatter.image.childImageSharp.resize.src
    const { title} = props.data.markdownRemark.frontmatter;
    // const { title, tags } = props.data.markdownRemark.frontmatter;
    // const { prev, next } = props.pageContext;
    return (
        <Main>
            <MetaTags
                title={title}
                description={props.data.markdownRemark.excerpt}
                thumbnail={thumbnail && url + thumbnail}
                url={url}
                pathname={props.location.pathname}
            />
            <div className={styles.main}>
                <div dangerouslySetInnerHTML={{ __html: props.data.markdownRemark.html }} />
                {/* <div>
                    <span>Tagged in </span>
                    {tags.map((tag, i) => (
                        <a href={`/blog/${tag}`} key={i}>{tag}</a>
                    ))}
                </div>
                <PrevNext prev={prev && prev.node} next={next && next.node} /> */}
            </div>
        </Main>
    )
}


export default BlogPost

export const query = graphql`

 query PostQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
       html
       excerpt
       frontmatter {
        title
        tags
        author
       }
   }
   site {
    siteMetadata {
        siteUrl
      }
   }
}
`